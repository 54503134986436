import backIcon from "@/assets/icons/back.svg";
import { CarrierImage } from "@/components/CarrierImage";
import { DEFAULT_TABLE_PROPS } from "@/constants";
import { normalizeDate, parseDate } from "@/helpers/string";
import {
  configAtom,
  selectedPlanAtom,
  selectedPlanConfigurationAtom,
  useNavigate,
} from "@/stores";
import {
  ContributionStrategy,
  EnrollmentPlan,
  Member,
  PlanConfiguration,
} from "@/types/api";
import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Group,
  Image,
  Select,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useAtom, useAtomValue } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useEffect, useMemo, useState } from "react";
import { AdditionalDetails } from "../../Review/AdditionalDetails";
import {
  contributionStrategyAtom,
  enrollmentsAtom,
  planInfoAtom,
  updatePlanAtom,
} from "../API";
import { ACADetailsModal } from "./ACADetailsModal/ACADetailsModal";

const planColumns = [
  {
    header: "",
    accessorKey: "group.carrier.id",
    Cell: ({ renderedCellValue }) => (
      <Center>
        <CarrierImage carrierId={renderedCellValue} />
      </Center>
    ),
  },
  {
    header: "plan type",
    accessorKey: "group.carrier.trade_name",
  },
  {
    header: "plan name",
    accessorKey: "plan_name",
  },
  {
    header: "group number",
    accessorKey: "group.group_number",
  },
  {
    header: "enrollees",
    accessorKey: "enrollees",
    Cell: ({ row }) => (
      <>
        {row.original.member_count} employees
        <br />
        {row.original.dependent_count} dependents
      </>
    ),
  },
];

const coverageTypeLabels: [
  string,
  keyof Omit<ContributionStrategy, "base_plan_configuration">,
][] = [
  ["Spouse", "member_spouse"],
  ["Child", "member_child"],
  ["Children", "member_children"],
  ["Family", "member_family"],
];

const NextButton = () => (
  <Text fw="bold" m={8}>
    Next
  </Text>
);
const PreviousButton = () => (
  <Text fw="bold" m={8}>
    Previous
  </Text>
);

export const AdminPlanDetails = () => {
  const navigate = useNavigate();
  const [planInfo] = useAtom(planInfoAtom[0]);
  const plan = useMemo(() => [planInfo], [planInfo]);
  const config = useAtomValue(configAtom);

  const [selectedPlanConfigId, setSelectedPlanConfigId] = useAtom(
    selectedPlanConfigurationAtom,
  );
  const [selectedPlanConfig, setSelectedPlanConfig] =
    useState<PlanConfiguration | null>(null);
  const [selectedSubclass, setSelectedSubclass] = useState<string | null>(null);

  const subclasses = useMemo(() => {
    return (
      plan[0]?.plan_configurations.map((planConfiguration) =>
        planConfiguration.required_subclass
          ? planConfiguration.required_subclass.name
          : "No Subclass",
      ) ?? []
    );
  }, plan);

  const subclassPlanConfigurationMapping = useMemo(() => {
    return plan[0].plan_configurations.reduce((acc, plan_configuration) => {
      if (plan_configuration.required_subclass) {
        acc[plan_configuration.required_subclass.name] = plan_configuration;
      } else {
        acc["No Subclass"] = plan_configuration;
      }
      return acc;
    }, {});
  }, [plan]);

  useEffect(() => {
    if (subclasses.length > 0) {
      if (selectedSubclass === null) {
        setSelectedSubclass(subclasses[0]);
      } else {
        const newPlanConfig =
          subclassPlanConfigurationMapping[selectedSubclass];
        if (newPlanConfig && selectedPlanConfigId !== newPlanConfig.id) {
          setSelectedPlanConfigId(newPlanConfig.id);
          setSelectedPlanConfig(newPlanConfig);
        }
      }
    }
  }, [selectedSubclass, subclassPlanConfigurationMapping, selectedPlanConfig]);

  const enrollmentColumns = [
    {
      header: "subscriber",
      accessorKey: "fullName",
    },
    {
      header: "effective start",
      accessorKey: "effectiveStart",
    },
    {
      header: "total premium",
      accessorKey: "totalPremium",
    },
    {
      header: "employer pay",
      accessorKey: "employerContribution",
    },
    {
      header: "member pay",
      accessorKey: "memberContribution",
    },
  ];

  const benefitsTable = useMantineReactTable({
    columns: planColumns,
    data: plan,
    ...DEFAULT_TABLE_PROPS,
  });
  const enrollments = useAtomValue(enrollmentsAtom);
  const planId = useAtomValue(selectedPlanAtom);
  const [_, updatePlan] = useAtom(updatePlanAtom);
  const queryClient = useAtomValue(queryClientAtom);

  // Get all members who are enrolled in the plan
  const filteredEnrollees = useMemo(() => {
    const planEnrollees = [];
    for (const enrollment of enrollments) {
      const enrolledPlans = enrollment.enrolled_plans as EnrollmentPlan[];
      const enrolledPlan = enrolledPlans.find(
        (enrolledPlan) => enrolledPlan.plan == planId,
      );

      if (
        enrolledPlan &&
        parseDate(enrolledPlan.effective_start) <= normalizeDate(new Date()) &&
        parseDate(enrolledPlan.effective_end) >= normalizeDate(new Date())
      ) {
        enrollment.member = enrollment.member as Member;
        planEnrollees.push({
          fullName: `${enrollment.member.first_name} ${enrollment.member.last_name}`,
          effectiveStart: enrolledPlan.effective_start,
          totalPremium: enrolledPlan.premium,
          employerContribution: enrolledPlan.employer_contribution,
          memberContribution: enrolledPlan.member_contribution,
        });
      }
    }
    return planEnrollees;
  }, [enrollments, planId]);

  const enrollmentTable = useMantineReactTable({
    columns: enrollmentColumns,
    data: filteredEnrollees,
    ...DEFAULT_TABLE_PROPS,
    positionGlobalFilter: "left",
    enableTopToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableFilterMatchHighlighting: false,
    enableBottomToolbar: true,
    paginationDisplayMode: "pages",

    mantinePaginationProps: {
      rowsPerPageOptions: ["10"],
      withEdges: false,
      showRowsPerPage: false,
      nextIcon: NextButton,
      previousIcon: PreviousButton,
      getItemProps: () => ({
        style: {
          display: "none",
        },
      }),
    },
  });
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleSaveAcaDetails = async (attributes) => {
    const updatedPlan = await updatePlan([
      { planId: plan[0].id, body: attributes },
    ]);
    queryClient.setQueryData(["plans", plan[0].id], updatedPlan);
  };

  const acaRequirementFulfilled =
    plan[0].provides_minimum_value &&
    plan[0].provides_essential_coverage &&
    plan[0].is_low_cost;

  return (
    <Stack gap="lg">
      <Title order={2}>
        <ActionIcon
          variant="subtle"
          size="lg"
          onClick={() => {
            navigate("admin");
          }}
        >
          <Image src={backIcon} />
        </ActionIcon>
        Your Benefits Summary
      </Title>

      {subclasses.length > 1 && (
        <Group>
          <Select
            label="Eligibility Class"
            size="sm"
            placeholder="Select Class"
            value={selectedSubclass}
            data={subclasses}
            onChange={setSelectedSubclass}
          />
        </Group>
      )}

      <MantineReactTable table={benefitsTable} />

      {selectedPlanConfig && <ContributionStrategyBanner />}
      {selectedPlanConfig && (
        <AdditionalDetails
          waitingPeriod={selectedPlanConfig.waiting_period}
          effectiveStart={planInfo.effective_start}
          effectiveEnd={planInfo.effective_end}
          terminationPolicy={selectedPlanConfig.termination_policy}
        />
      )}
      {acaRequirementFulfilled && (
        <Text style={{ color: "green" }}>This plan meets ACA requirements</Text>
      )}
      {plan[0].line_of_coverage === "medical" &&
        config.apiClient !== "production" && (
          <>
            <Button onClick={toggleModal} style={{ width: "25%" }}>
              Confirm ACA Details
            </Button>
            <ACADetailsModal
              isOpen={isModalOpen}
              onClose={toggleModal}
              onSave={handleSaveAcaDetails}
              plan={plan[0]}
            />
          </>
        )}
      <Title order={2}>Enrollment Summary</Title>
      <MantineReactTable table={enrollmentTable} />
    </Stack>
  );
};

const ContributionStrategyBanner = () => {
  const [contributionStrategy] = useAtom(contributionStrategyAtom[0]);

  if (contributionStrategy.base_plan_configuration) {
    return (
      <Alert
        title="Here is how premium contributions are split between your employees and the company."
        icon={<IconInfoCircle />}
      >
        <Space />
        The company matches the contributions of the base plan (
        <b>
          {
            (contributionStrategy.base_plan_configuration as PlanConfiguration)
              .plan.plan_name
          }
        </b>
        ).
      </Alert>
    );
  }
  return (
    <Alert
      title="Here is how premium contributions are split between your employees and the company."
      icon={<IconInfoCircle />}
    >
      <b>Subscribers:</b> The{" "}
      {getContributionStrategyDisplayText("member", contributionStrategy)} of
      the premium.
      {coverageTypeLabels.map(([label, attr]) => (
        <>
          <Space />
          <b>{label}</b>: The{" "}
          {getContributionStrategyDisplayText(attr, contributionStrategy)} of
          the additional premium.
        </>
      ))}
    </Alert>
  );
};

function getContributionStrategyDisplayText(
  coverageType: keyof Omit<ContributionStrategy, "base_plan_configuration">,
  contributionStrategy: ContributionStrategy,
) {
  if (contributionStrategy.base_plan_configuration) {
    return;
  }
  const contribution = contributionStrategy[coverageType];
  const additionalText = "";
  if (contribution.monthly_min_threshold) {
    additionalText.concat(` Min of $${contribution.monthly_min_threshold}`);
  }
  if (contribution.monthly_max_threshold) {
    additionalText.concat(` Max of $${contribution.monthly_max_threshold}`);
  }
  switch (contribution.contribution_type) {
    case "employer_percentage":
      let percentage = contribution.contribution;
      if (percentage.endsWith(".00")) {
        percentage = percentage.split(".")[0];
      }
      return `company covers ${percentage}%${additionalText}`;
    case "employer_cost":
      return `company covers $${contribution.contribution}${additionalText}`;
    case "member_cost":
      return `member covers $${contribution.contribution}${additionalText}`;
  }
}
