import { ENROLLMENT_REASONS } from "@/constants";
import { parseDate } from "@/helpers/string";
import { selectedEnrollmentAtom, useNavigate } from "@/stores";
import { Employer, Enrollment } from "@/types/api";
import {
  Anchor,
  Badge,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconHelpCircle } from "@tabler/icons-react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  memberInfoAtom,
  postBeginOpenEnrollmentAtom,
  postNewHireEnrollmentAtom,
} from "../API";
import { BenefitIneligibleBanner } from "./BenefitIneligibleBanner";

export const CTABanner = () => {
  const member = useAtomValue(memberInfoAtom[0]);
  const pendingEnrollments = (member.enrollments as Enrollment[]).filter(
    (enrollment) => ["pending", "approved"].includes(enrollment.status),
  );

  const employer = member.employer as Employer;

  if (!member.benefits_eligible) {
    // Show benefit-ineligible banner for employees who are not eligible
    // for benefits. This currently applies to union members.
    return <BenefitIneligibleBanner />;
  }

  // When a pending OE/QLE is present, show the pending enrollment banner
  if (pendingEnrollments.length) {
    return <PendingEnrollmentsBanner pendingEnrollments={pendingEnrollments} />;
  }

  if (!member.active_enrollment && member.is_new_hire) {
    return <NewHireBanner />;
  }

  if (
    employer.upcoming_open_enrollment &&
    new Date().toLocaleDateString("sv") >=
      employer.upcoming_open_enrollment.start_date &&
    new Date().toLocaleDateString("sv") <=
      employer.upcoming_open_enrollment.end_date &&
    !pendingEnrollments.find(
      (enrollment) => enrollment.category === "open_enrollment",
    )
  ) {
    // Show OE banner when in the OE window and no enrollment
    // has been submitted
    return <OpenEnrollmentBanner />;
  }

  // Default to the QLE banner in all other cases
  return <QLEBanner />;
};

const QLEBanner = () => {
  const navigate = useNavigate();
  return (
    <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "48rem" }}>
      <Stack gap={12}>
        <Text fw={600}>Any major life changes?</Text>
        <Text>
          Ensure your benefits align with your current situation. Changes like
          marriage, divorce, or having a child could qualify you for new
          coverage options.
        </Text>
        <Group align="center">
          <Button onClick={() => navigate("qle")}>Update your benefits</Button>
          <Anchor
            href="https://www.healthcare.gov/glossary/qualifying-life-event/"
            target="_blank"
          >
            What is a qualified life event?
          </Anchor>
        </Group>
      </Stack>
    </Paper>
  );
};

const OpenEnrollmentBanner = () => {
  const member = useAtomValue(memberInfoAtom[0]);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [, postBeginOpenEnrollment] = useAtom(postBeginOpenEnrollmentAtom);
  const setSelectedEnrollment = useSetAtom(selectedEnrollmentAtom);

  const onBeginEnrollment = async () => {
    const enrollment = await postBeginOpenEnrollment([undefined]);
    setSelectedEnrollment(enrollment);
    navigate("enroll");
  };
  const formattedOEEndDate = parseDate(
    (member.employer as Employer).upcoming_open_enrollment.end_date,
  ).toLocaleDateString("en-US", { month: "long", day: "numeric" });

  return (
    <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "48rem" }}>
      <Stack gap={12}>
        <Group justify="space-between">
          <Group gap={2}>
            <Text fw={600}>It's time to enroll in benefits</Text>
            <Tooltip
              label={`Open enrollment is the only time of the year where all employees 
              are eligible to make changes to their insurance enrollments. Even if you 
              are waiving coverage or keeping the same selections as the previous year 
              you must submit a new enrollment.`}
            >
              <IconHelpCircle
                stroke={1.5}
                size={25}
                color={theme.colors[theme.primaryColor][5]}
              />
            </Tooltip>
          </Group>
          <Badge variant="light" style={{ textTransform: "none" }}>
            Deadline {formattedOEEndDate}
          </Badge>
        </Group>
        <Text>
          Open enrollment is currently underway. Select or waive your insurance
          options prior to the deadline to ensure your coverage is in place for
          the year ahead.
        </Text>
        <Group align="center">
          <Button onClick={onBeginEnrollment}>Begin enrolling</Button>
        </Group>
      </Stack>
    </Paper>
  );
};

const PendingEnrollmentsBanner = ({
  pendingEnrollments,
}: {
  pendingEnrollments: Enrollment[];
}) => {
  const navigate = useNavigate();
  const setSelectedEnrollment = useSetAtom(selectedEnrollmentAtom);
  const onViewDetails = (enrollment: Enrollment) => {
    setSelectedEnrollment(enrollment);
    navigate("enrollment_details");
  };

  return (
    <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "48rem" }}>
      <Stack gap={12}>
        <Text fw={600}>Pending Enrollments</Text>
        <Text>
          The following changes are still being processed. We will let you know
          once these benefits are in effect.
        </Text>

        {pendingEnrollments.map((enrollment) => (
          <>
            <Group justify="space-between">
              <Text>
                <b>{ENROLLMENT_REASONS[enrollment.reason]}</b> (Initiated on{" "}
                {parseDate(enrollment.created_at)
                  .toISOString()
                  .substring(0, 10)}
                )
              </Text>
              <Button
                variant="subtle"
                onClick={() => onViewDetails(enrollment)}
              >
                View Details
              </Button>
            </Group>
            <Divider />
          </>
        ))}
      </Stack>
    </Paper>
  );
};

const NewHireBanner = () => {
  const member = useAtomValue(memberInfoAtom[0]);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [, postNewHireEnrollment] = useAtom(postNewHireEnrollmentAtom);
  const setSelectedEnrollment = useSetAtom(selectedEnrollmentAtom);

  const onBeginNewHireEnrollment = async () => {
    const enrollment = await postNewHireEnrollment([undefined]);
    setSelectedEnrollment(enrollment);
    navigate("enroll");
  };
  return (
    <Paper p="lg" shadow="xs" withBorder style={{ maxWidth: "48rem" }}>
      <Stack gap={12}>
        <Text fw={600}>It's time to enroll in benefits</Text>
        <Text>
          You are now eligible to enroll in benefits! Select or waive your
          benefits options soon to ensure your coverage is in place for the year
          ahead.
        </Text>
        <Group align="center">
          <Button onClick={onBeginNewHireEnrollment}>Begin enrolling</Button>
        </Group>
      </Stack>
    </Paper>
  );
};
