import { DEFAULT_CONFIG } from "@/constants";
import { getCursor } from "@/helpers";
import { fetchAllPages } from "@/helpers/hooks";
import { ClaspEmployeeConfig, ClaspEmployerConfig, linkId } from "@/types";
import { Carrier, Enrollment, Paginated, PartnerConfig } from "@/types/api";
import { PrimitiveAtom, atom, useAtomValue, useSetAtom } from "jotai";
import { atomsWithInfiniteQuery, atomsWithQuery } from "jotai-tanstack-query";
import { useEffect, useMemo } from "react";
import { APIClient } from "./APIClient";

// @ts-expect-error TODO: fix atom types
export const configAtom = atom<ClaspEmployerConfig | ClaspEmployeeConfig>();
export const entityIdAtom = atom<string>(
  localStorage.getItem("member_id") || localStorage.getItem("employer_id"),
);
export const selectedPlanAtom = atom<string>("");
export const selectedPlanConfigurationAtom = atom<string>("");
export const claspStateAtom = atom<string>("");
export const selectedEnrollmentAtom = atom<Enrollment | null>(
  null,
) as PrimitiveAtom<Enrollment | null>;

export const clientAtom = atom<Promise<APIClient>>(async (get) => {
  const config = get(configAtom);
  let client: APIClient;
  client = new APIClient(config);
  if ("employerId" in config) {
    await client.putConnection(config.employerId);
  }
  return client;
});

export const carriersInfiniteQueryAtom: ReturnType<
  typeof atomsWithInfiniteQuery<Paginated<Carrier>>
> = atomsWithInfiniteQuery<Paginated<Carrier>>((get) => ({
  queryKey: ["carriers"],
  queryFn: async ({ pageParam }) => {
    const client = await get(clientAtom);
    return client.getCarriers({ cursor: pageParam as string | undefined });
  },
  getNextPageParam: (lastPage) => getCursor(lastPage.next),
  initialPageParam: undefined,
  staleTime: 15 * 60 * 1000, // Cache for 15 minutes
}));
export const carriersAtom = atom<Promise<Map<string, Carrier>>>(async (get) => {
  const allCarriers = fetchAllPages(
    await get(carriersInfiniteQueryAtom[0]),
    get(carriersInfiniteQueryAtom[1]),
  );
  return new Map(allCarriers.map((carrier) => [carrier.id, carrier]));
});

export const useExternalNavigate = () => {
  const config = useAtomValue(configAtom);

  const navigate = (linkId: linkId) => {
    const link = config.links?.[linkId] || "/";
    if (typeof link === "string") {
      window.top.location.href = link;
    } else {
      link();
    }
  };
  return navigate;
};

export const useNavigate = () => {
  const config = useAtomValue(configAtom);
  const setClaspState = useSetAtom(claspStateAtom);
  const navigate = useMemo(
    () => (claspState: string) => {
      if (config?.navigation === "url") {
        const url = new URL(window.location.href);
        url.searchParams.set("claspState", claspState);
        const pathWithQueryAndHash = url.pathname + url.search + url.hash;
        config.onNavigate(pathWithQueryAndHash);
      }
      setClaspState(claspState);
    },
    [config, setClaspState],
  );
  return navigate;
};

export const useClasp = (
  config: ClaspEmployerConfig | ClaspEmployeeConfig | undefined,
) => {
  const setConfig = useSetAtom(configAtom);

  return useEffect(() => {
    if (config) setConfig(config);
    else setConfig(DEFAULT_CONFIG);
  }, [config, setConfig]);
};

export const partnerConfigAtom: ReturnType<
  typeof atomsWithQuery<PartnerConfig>
> = atomsWithQuery<PartnerConfig>((get) => ({
  queryKey: ["partner_config"],
  queryFn: async () => {
    const client = await get(clientAtom);
    return await client.getPartnerConfig();
  },
  staleTime: 15 * 60 * 1000, // Cache for 15 minutes
}));
