import {
  CUSTOM_VOLUME_LOCS,
  SUPPLEMENTAL_LOCS,
  SUPPLEMENTAL_LOC_NAMES,
} from "@/constants";
import { LineOfCoverage, PlanQuote } from "@/types/api";
import { Accordion, Group, Stack, Text, Title } from "@mantine/core";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { getEligiblePlansAtom, getEnrollmentAtom } from "../../API";
import {
  PlanQuoteWithDependents,
  useEnrollContext,
} from "../../EnrollProvider";
import { VoluntarySupplementalBenefitCard } from "../VoluntarySupplementalBenefitCard";
import { useVoluntarySupplementalBenefitCardFormContext } from "../VoluntarySupplementalBenefitCard/form";
import { BasicSupplementalBenefitCard } from "./BasicSupplementalBenefitCard";

export const SupplementalStep = () => {
  const [openedPanels, setOpenedPanels] = useState([]);

  const [, fetchEligiblePlans] = useAtom(getEligiblePlansAtom);
  const [eligiblePlans, setEligiblePlans] = useState<PlanQuote[]>([]);
  const enrollment = useAtomValue(getEnrollmentAtom[0]);
  const {
    state: { supplemental: selected },
    dispatch,
  } = useEnrollContext();

  const onSelectHelper = (
    lineOfCoverage: LineOfCoverage,
    selectedQuote: PlanQuoteWithDependents | null,
  ) => {
    dispatch({
      type: "set-supplemental",
      payload: new Map(selected.set(lineOfCoverage, selectedQuote)),
    });

    setOpenedPanels((openedPanels) =>
      openedPanels.filter((value) => value !== lineOfCoverage),
    );
  };

  useEffect(() => {
    fetchEligiblePlans([
      {
        body: { dependents: [] },
        enrollmentId: enrollment.id,
      },
    ]).then((quotes) => {
      // If we already have a quote for a given LOC then we should use that cost
      // This is mainly the case when we reopened an existing enrollment
      const mergedQuotes = quotes.map((quote) => {
        const selectedQuote = selected.get(quote.plan.line_of_coverage)?.quote;
        return selectedQuote?.plan.id == quote.plan.id ? selectedQuote : quote;
      });
      setEligiblePlans(mergedQuotes);
      setOpenedPanels(quotes.map((quote) => quote.plan.line_of_coverage));

      // If the plan requires enrollment then automatically enroll the member
      quotes.forEach((quote) => {
        if (quote.plan.required_enrollment) {
          onSelectHelper(quote.plan.line_of_coverage, {
            quote,
            dependents: [],
          });
        }
      });
    });
  }, [enrollment, fetchEligiblePlans]);

  const planQuotes = useMemo(
    () =>
      eligiblePlans.filter((planQuote) =>
        SUPPLEMENTAL_LOCS.includes(planQuote.plan.line_of_coverage),
      ),
    [eligiblePlans],
  );

  const planQuoteMap = new Map(
    planQuotes.map((quote) => [quote.plan.id, quote]),
  );

  return (
    <Stack gap={24}>
      <Title size="h3">Optional Supplemental Benefits Enrollment</Title>
      <Accordion
        multiple
        variant="separated"
        value={openedPanels}
        onChange={setOpenedPanels}
      >
        {planQuotes
          .sort(
            (a, b) =>
              SUPPLEMENTAL_LOCS.indexOf(a.plan.line_of_coverage) -
              SUPPLEMENTAL_LOCS.indexOf(b.plan.line_of_coverage),
          )
          .map((planQuote) => (
            <Accordion.Item value={planQuote.plan.line_of_coverage}>
              <Accordion.Control>
                <Group wrap="nowrap" justify="space-between">
                  <Text size="xl" fw={700}>
                    {SUPPLEMENTAL_LOC_NAMES[planQuote.plan.line_of_coverage]}
                  </Text>
                  <StatusTag
                    plan={selected.get(planQuote.plan.line_of_coverage)?.quote}
                  />
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                {CUSTOM_VOLUME_LOCS.some(
                  (loc) => loc === planQuote.plan.line_of_coverage,
                ) ? (
                  <VoluntarySupplementalBenefitCard
                    initialPlanQuote={planQuote}
                    onSubmit={onSelectHelper}
                    selected={selected}
                    planQuoteMap={planQuoteMap}
                  />
                ) : (
                  <BasicSupplementalBenefitCard
                    initialPlanQuote={planQuote}
                    onSubmit={onSelectHelper}
                    selected={selected}
                    planQuoteMap={planQuoteMap}
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
      </Accordion>
    </Stack>
  );
};

const StatusTag = ({ plan }: { plan?: PlanQuote }) => {
  const form = useVoluntarySupplementalBenefitCardFormContext();
  const volSupPlanWaived = form.values[plan?.plan?.line_of_coverage]?.every(
    (entity) => entity.volume === "0",
  );

  if (plan === undefined) {
    return <></>;
  } else if (plan === null || volSupPlanWaived) {
    return (
      <Text
        style={(theme) => ({
          color: theme.colors.red[3],
        })}
        pr={10}
      >
        Waived
      </Text>
    );
  }

  return (
    <Text
      style={(theme) => ({
        color: theme.colors[theme.primaryColor][5],
      })}
      pr={10}
    >
      Enrolled
    </Text>
  );
};
